import { useState, useEffect, useContext, useMemo } from 'react';
import { ClientContext } from '../../../../contexts/clientProvider';
import LoadingSpinner from '../../../Loader/load';
import DataTable from '../../../Table';
import { FaturadorContext } from '../../../../contexts/faturadorProvider';
import { BiX } from "react-icons/bi";
import FilterDate from '../filterDate';
import FrameSelect from '../../../General/FrameSelect';

const Setor = () => {
  const { clientes, setIdCliente } = useContext(ClientContext);
  const { patrimonios, loading, total, calculateTotal, toBrl, clienteSelecionado, setClienteSelecionado, filterDates, setFilterDates } = useContext(FaturadorContext);
  const [filteredTotal, setFilteredTotal] = useState(total); 
  const [selectedSetor, setSelectedSetor] = useState([]);
  const [selectedModelo, setSelectedModelo] = useState('');
  const [selectedAnexos, setSelectedAnexos] = useState([]);
  const [setor, setSetor] = useState([]);
  const [anexos, setAnexos] = useState([]);
  const [titulo, setTitulo] = useState(null);
  const [filterModal, setFilterModal] = useState(false);
  const [checkUpdate, setCheckUpdate] = useState(false);
  const [newPats, setNewPats] = useState([]);
  const [showAnexos, setShowAnexos] = useState([]);

  useEffect(() => {
    if (!patrimonios) return;
    let newPats = patrimonios.map((pat) => {
      if(!pat.produto){
        pat.produto = `${pat.marca_nome} ${pat.modelo_nome}`;
      }
      if(pat.data_implantacao){
        pat.data_implantacao = formatDate(pat.data_implantacao.split('T')[0]);
        return pat;
      }
      return pat;
    });
    setNewPats(newPats);
  },[patrimonios]);

  const handleAnexoChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'Todos' && anexos.length > 1) {
      setSelectedAnexos(prev => {
        const anexosRestantes = anexos.filter((anexo) => anexo !== 'Todos' && !prev.includes(anexo));
        return [...prev, ...anexosRestantes]; 
      });
      setShowAnexos(prev => {
        const anexosRestantes = anexos.filter((anexo) => anexo !== 'Todos' && !prev.includes(anexo));
        return [...prev, ...anexosRestantes]; 
      });
    } else {
      setSelectedAnexos(prev => {
        if (!prev.includes(selectedValue) && selectedValue !== 'Todos') {
          return [...prev, selectedValue];
        }
        return prev;
      });
      setShowAnexos(prev => {
        if (!prev.includes(selectedValue) && selectedValue !== 'Todos') {
          return [...prev, selectedValue];
        }
        return prev;
      });
    }
  };

  const handleRemoveAnexo = (index) => {
    setSelectedAnexos(prev => {
      const removedAnexo = prev[index];
      setAnexos(prev => {
        if (!prev.includes(removedAnexo)) {
          return [...prev, removedAnexo];
        }
        return prev;
      });
      return prev.filter((_, i) => i !== index);
    });
    setShowAnexos(prev => {
      const removedAnexo = prev[index];
      setAnexos(prev => {
        if (!prev.includes(removedAnexo)) {
          return [...prev, removedAnexo];
        }
        return prev;
      });
      return prev.filter((_, i) => i !== index);
    });
  };

  const removeAllAnexos = () => {
    setSelectedAnexos([]); 
    setShowAnexos([]); 
    setAnexos(['Todos', ...new Set(patrimonios.map(p => p.nome_do_anexo))]);
  };

  const removeAllSetores = () => {
    setSelectedSetor([]);
    setSetor(['Todos', ...new Set(patrimonios.map(p => p.setor))]);
  };

  const handleSetorChange = (e) => {    
    const selectedValue = e.target.value;
    if (selectedValue === 'Todos' && setor.length > 1) {
      setSelectedSetor(prev => {
        const setoresRestantes = setor.filter((setor) => setor !== 'Todos' && !prev.includes(setor));
        return [...prev, ...setoresRestantes]; 
      });
    } else if (selectedValue === 'Remover todos' && selectedSetor.length > 1) {
      
    } else {
      setSelectedSetor(prev => {
        if (!prev.includes(selectedValue) && selectedValue !== 'Todos') {
          return [...prev, selectedValue];
        }
        return prev;
      });
    }
  };

  const handleRemoveSetor= (index) => {
    setSelectedSetor(prev => {
      const removedSetor = prev[index];
      setSetor(prev => {
        if (!prev.includes(removedSetor)) {
          return [...prev, removedSetor];
        }
        return prev;
      });
      return prev.filter((_, i) => i !== index);
    });
  };

  const verifyAnexosBySetor = (f) => {
    let anex = new Set();
    f.forEach((pat) => {
      if (selectedSetor.includes(pat.setor)) {
        anex.add(pat.nome_do_anexo);
      }
    });
    setShowAnexos([...anex]);
  }

  const filteredPatrimonios = useMemo(() => {
    let filtered = newPats;
    if (selectedSetor.length > 0) {
      if (selectedAnexos.length > 0) {
        filtered = filtered.filter(p => selectedAnexos.includes(p.nome_do_anexo));
        verifyAnexosBySetor(filtered);
      } else {
        filtered = filtered.filter(p => selectedSetor.includes(p?.setor));
      }
    }
    if (selectedModelo) {
      filtered = filtered.filter(p => p.modelo_nome === selectedModelo);
    }
    if (selectedAnexos.length > 0) {
      if (selectedSetor.length > 0) {
        filtered = filtered.filter(p => selectedSetor.includes(p.setor));
      } else {
        filtered = filtered.filter(p => selectedAnexos.includes(p.nome_do_anexo));
      }
    }
    return filtered;
  }, [selectedSetor, selectedModelo, selectedAnexos, newPats]);

  useEffect(() => {
    const newTotal = calculateTotal(filteredPatrimonios);
    setFilteredTotal(newTotal);
  }, [filteredPatrimonios]);

  const handleSelectClient = (e) => {
    const id = e.target.value;
    const selectedClient = clientes.find((client) => client.id === parseInt(id));
    
    if (selectedClient) {
      setTitulo(selectedClient.nome);
      setIdCliente(id);
      setSelectedAnexos([]); 
      setShowAnexos([]);
      setSelectedSetor([]);
    }
    setClienteSelecionado(selectedClient.nome);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const eraseFilter = () => {
    setFilterDates({ dataInicio: null, dataFim: null, checkUpdate: false });
    const client = clientes.find((client) => client.nome === clienteSelecionado);
    setIdCliente(client.id);
  };

  const handleDateModal = () => {
    setFilterModal(!filterModal);
  };

  const handleUnique = () => {
    const uniqueAnexos = [...new Set(patrimonios.map(p => p.nome_do_anexo))];
    const uniqueSetor = [...new Set(patrimonios.map(p => p.setor))];
    if (uniqueSetor.length > 1) {
      uniqueSetor.unshift('Todos');
      setSetor(uniqueSetor);
    }
    if (uniqueAnexos.length > 1) {
      uniqueAnexos.unshift('Todos');
      setAnexos(uniqueAnexos);
    }
  }

  useEffect(() => {
    handleUnique(); 
  }, [patrimonios]);

  useEffect(() => {
    setSetor(prev => prev.filter(setor => !selectedSetor.includes(setor)));
  }, [selectedSetor]);

  // useEffect(() => {
  //   setAnexos(prevAnexos => prevAnexos.filter(anexo => !selectedAnexos.includes(anexo)));
  // }, [selectedAnexos, showAnexos]);
 
  const headers = [
    filteredPatrimonios[0]?.data_implantacao && { label: "Data de Implantação", key: "data_implantacao", sortable: false },
    { label: "Anexo", key: "nome_do_anexo", sortable: true },
    { label: "Setor", key: "setor", sortable: false },
    { label: "Nome", key: "pat_nome", sortable: false },
    { label: "Equipamento", key: "produto", sortable: false },
    { label: "Marca", key: "marca_nome", sortable: false },
    { label: "Modelo", key: "modelo_nome", sortable: false },
    { label: "MAC", key: "nmac", sortable: false },
    { label: "Serial Number", key: "nserie", sortable: false },
    { label: "Nº Etiqueta", key: "netiqueta", sortable: false },
    { label: "Valor", key: "valor_cobrado", sortable: false },
  ].filter((header) => header);

  return (
    <>
      <div className="filters-container">
        <select className="anexos-consulta-select" onChange={handleSelectClient}>
        <option value="">{clienteSelecionado ?? "Selecione o cliente"}</option>
          {clientes?.slice().sort((a, b) => a.nome.localeCompare(b.nome)).map((client, index) => (
            <option key={index} value={client.id}>
              {client.nome}
            </option>
          ))}
        </select>

        <select className="anexos-consulta-select" value={selectedSetor?.length ? selectedSetor[selectedSetor.length - 1] : ""} onChange={(e) => handleSetorChange(e)}>
          <option value="">Selecione o Setor</option>
          {setor.map((setor, index) => (
            <option key={index} value={setor}>{setor}</option>
          ))}
        </select>

        <select className="anexos-consulta-select" value={selectedModelo} onChange={(e) => setSelectedModelo(e.target.value)}>
          <option value="">Selecione o Modelo</option>
          {[...new Set(patrimonios.map(p => p.modelo_nome))].map((modelo, index) => (
            <option key={index} value={modelo}>{modelo}</option>
          ))}
        </select>

        <select className="anexos-consulta-select" value={selectedAnexos?.length ? selectedAnexos[selectedAnexos.length - 1] : ""}  onChange={(e) => handleAnexoChange(e)}>
          <option value="">Selecione o Anexos</option>
          {anexos.map((anexo, index) => (
            <option key={index} value={anexo}>{anexo}</option>
          ))}
        </select>
    </div>

      {clienteSelecionado && (
        <>
        {!filterDates.dataInicio && !filterDates.dataFim ? (
            <button className='anexos-cadastro-button' onClick={() => setFilterModal(true)}>Filtrar por data de implantação</button>
          ) : (
            <div className="info-container" style={{ marginBottom: '10px' }}>
              <span>
                Data Inicial: {formatDate(filterDates.dataInicio)}
              </span>
              <span>
                Data Final: {formatDate(filterDates.dataFim)}
              </span>
              <span>
                Busca por atualização do patrimônio: {checkUpdate ? 'Sim' : 'Não'}
              </span>
              <BiX onClick={eraseFilter} style={{ color: '#142a4c', cursor: 'pointer' }} />
            </div>
          )}
        </>
      )}
       {/* <input
        type="text"
        placeholder="Buscar anexos..."
        onChange={(e) => console.log(e.target.value)}
        style={{
          padding: '10px',
          width: '100%',
          border: '1px solid #ccc',
          borderRadius: '4px',
          marginBottom: '20px'
        }}
      /> */}
      
      {/* {selectedAnexos.length > 0 && (
        <div className="info-anexos-container" style={{ marginBottom: "10px" }}>
          {selectedAnexos.map((anexo, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <button onClick={() => handleRemoveAnexo(index)} className="remove-button">x</button>
              <span style={{ marginRight: '10px', marginLeft: '5px' }}>{anexo}</span>
            </div>
          ))}
        </div>
      )} */}
      <FrameSelect anexos={showAnexos} removeAnexo={handleRemoveAnexo} setores={selectedSetor} removeSetor={handleRemoveSetor} removeAllAnexos={removeAllAnexos} removeAllSetores={removeAllSetores}/>
      <div className="info-container">
        <span>Número de Aparelhos Contados: {filteredPatrimonios.length}</span>
        <span>Valor Total de Aparelhos: {toBrl(filteredTotal)}</span>
      </div>
      {loading && <LoadingSpinner />}
      {filteredPatrimonios && <DataTable headers={headers} data={filteredPatrimonios} itemsPerPage={5} exportButton={true} docTitle={`Dados gerias do cliente ${titulo}`} />}
      {filterModal && <FilterDate filterDates={filterDates} setFilterDates={setFilterDates} closeModal={handleDateModal} checkUpdate={checkUpdate} setCheckUpdate={setCheckUpdate} />}
    </>
  );
};

export default Setor;
