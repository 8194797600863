// mixerUtil.js
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import FileSaver from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts'; 
/**
 * Lida com a importação de um arquivo, lê seu conteúdo e o converte para CSV.
 * @param {File} file - O arquivo a ser importado.
 * @param {Function} callback - A função de callback para lidar com os dados parseados.
 */
export const handleImport = (file, callback) => {
    if (!file) return;

    let reader = new FileReader();
    reader.onload = function (e) {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        Papa.parse(data, {
            complete: (r) => {
                callback(r.data); 
            }
        });
    };

    reader.readAsBinaryString(file);
};

/**
 * Calcula os resultados com base nos dados fornecidos e na secretaria atual.
 * @param {Array} data - Os dados importados.
 * @param {Array} currSec - Os dados da secretaria atual.
 * @param {number} vc1Value - O valor para VC1.
 * @param {number} vc2Value - O valor para VC2.
 * @param {number} vc3Value - O valor para VC3.
 * @param {number} lcValue - O valor para LC.
 * @param {number} ldnValue - O valor para LDN.
 * @returns {Array} - Os novos dados processados.
 */
export const calculateResults = (data, currSec, vc1Value, vc2Value, vc3Value, lcValue, ldnValue) => {
    let newData = [];

    for (const sec of currSec || []) {
        let existed = newData.find(d => d.secretaria === sec.secretaria);
        if (existed) {
            existed.linhas = [...existed.linhas, sec.linhas];
        } else {
            newData.push({ ...sec, data: [], linhas: [sec.linhas] });
        }
    }

    if (data.length > 0) {
        data.slice(1).forEach((row, index) => {
            const phoneNumber = row[2]?.replace(/\D/g, '').trim(); 

            if (!phoneNumber) return; 

            let num = phoneNumber;

            if (num.length > 9) {
                const shortenedNum = invert(invert(num).substring(0, 8));
                if (shortenedNum.length <= 9) {
                    num = shortenedNum;
                }
            }

            let found = false;
            newData = newData.map(d => {
                if (d.linhas.includes(num)) {
                    d.data.push({ ...row, excLine: index + 2 }); 
                    found = true;
                }
                return d;
            });

            if (!found) {
                newData.push({
                    'secretaria': num,
                    'linhas': [num],
                    'data': [{ ...row, excLine: index + 2 }]
                });
            }
        });

        newData = processResults(newData, vc1Value, vc2Value, vc3Value, lcValue, ldnValue);
        return newData;
    }
};

/**
 * Processa os resultados para calcular tempos e valores.
 * @param {Array} newData - Os novos dados a serem processados.
 * @param {number} vc1Value - O valor para VC1.
 * @param {number} vc2Value - O valor para VC2.
 * @param {number} vc3Value - O valor para VC3.
 * @param {number} lcValue - O valor para LC.
 * @param {number} ldnValue - O valor para LDN.
 * @returns {Array} - Os novos dados processados.
 */
const processResults = (newData, vc1Value, vc2Value, vc3Value, lcValue, ldnValue) => {
    let idCounter = 1;
    newData = newData.map((sec) => {
        //console.log('linhas', sec.linhas);
        let newSec = { ...sec };

        newSec['id'] = idCounter++;
        newSec["bairro"] = sec.bairro ?? '';
        newSec["endereco"] = sec.endereco ?? '';
        newSec["local"] = sec.local ?? '';
        newSec["Tempo VC1"] = getCallTime(sec.data, 'VC1') ?? '';
        newSec["Tempo VC2"] = getCallTime(sec.data, 'VC2') ?? '';
        newSec["Tempo VC3"] = getCallTime(sec.data, 'VC3') ?? '';
        newSec["Tempo LC"] = getCallTime(sec.data, 'LC') ?? '';
        newSec["Tempo LDN"] = getCallTime(sec.data, 'LDN')  ?? '';
        newSec["linhas"] = sec.linhas.join(', ');

        newSec["Valor VC1"] = evaluate(newSec["Tempo VC1"], vc1Value) ?? '';
        newSec["Valor VC2"] = evaluate(newSec["Tempo VC2"], vc2Value) ?? '';
        newSec["Valor VC3"] = evaluate(newSec["Tempo VC3"], vc3Value) ?? '';
        newSec["Valor LC"] = evaluate(newSec["Tempo LC"], lcValue) ?? '';
        newSec["Valor LDN"] = evaluate(newSec["Tempo LDN"], ldnValue) ?? '';
        newSec.Fatura = faturate(newSec) ?? '';
        let saperval = 0
        sec.data.forEach(d => {
            let val = d[8]//?.replace(/[^\d.,]/g, '')
            if (isNaN(val)) {
                val = 0
            }
            else {
                saperval = parseFloat(val) + parseFloat(saperval)
                if (saperval.length > 2) {
                    saperval = saperval.toFixed(5)
                }
            }
        });
        newSec.Detalhes = [{
            secretaria: sec.secretaria,
            data: sec.data,
            callback: downloadNewSecAndData,
            'linhas': sec.linhas,
            'Tempo VC1': newSec["Tempo VC1"],
            'Valor VC1': newSec["Valor VC1"],
            'Tempo VC2': newSec["Tempo VC2"],
            'Valor VC2': newSec["Valor VC2"],
            'Tempo VC3': newSec["Tempo VC3"],
            'Valor VC3': newSec["Valor VC3"],
            'Tempo LC': newSec["Tempo LC"],
            'Valor LC': newSec["Valor LC"],
            'Tempo LDN': newSec["Tempo LDN"],
            'Valor LDN': newSec["Valor LDN"],
            'Fatura': newSec.Fatura,
            'SaperVal': newSec.SaperVal,
            'ISOT': newSec.ISOT 
        }];

        newSec.SaperVal = "R$" + saperval.toFixed(2) ?? '';
        return newSec;
    });

    newData = newData.sort((a, b) => a.secretaria.localeCompare(b.secretaria));
    return newData;
};

/**
 * Faz o download dos dados fornecidos como um arquivo Excel.
 * @param {Array} data - Os dados a serem salvos.
 * @param {string} secretaria - O nome da secretaria.
 */
export const downloadNewSecAndData = (data, secretaria) => {
    // Cabeçalho do Excel
    const header = ["MES_REFERENCIA", "DATA", "ORIGEM", "DESTINO", "TEMPO_TOTAL", "TEMPO_REAL", "TIPO", "LOCALIDADE", "VALOR", "EXCILNE"];
    
    // Transformar a matriz de objetos em uma matriz de arrays
    const dataSave = [
        header,
        ...data.map(item => [
            item[0], // MES_REFERENCIA
            item[1], // DATA
            item[2], // ORIGEM
            item[3], // DESTINO
            item[4], // TEMPO_TOTAL
            item[5], // TEMPO_REAL
            item[6], // TIPO
            item[7], // LOCALIDADE
            item[8], // VALOR
            item.excLine // EXCILNE
        ])
    ];

    const secretariaName =  secretaria ?? data[0][2];
    // Criar a planilha a partir da matriz de arrays
    const ws = XLSX.utils.aoa_to_sheet(dataSave);

    // Criar o livro de trabalho (workbook) com a planilha
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

    // Gerar o buffer de dados do Excel
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Criar o Blob para download do arquivo Excel
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Usa FileSaver para baixar o arquivo Excel
    FileSaver.saveAs(blob, `${secretariaName}-relatorio.xlsx`);
};





/**
 * Inverte uma string fornecida.
 * @param {string} str - A string a ser invertida.
 * @returns {string} - A string invertida.
 */
const invert = (str) => {
    let newStr = '';
    for (let i = str.length - 1; i >= 0; i--) {
        newStr += str[i];
    }
    return newStr;
};

/**
 * Avalia o custo com base nos minutos e em um valor de estado.
 * @param {number} minutes - O número de minutos.
 * @param {number} stateVal - O valor do estado.
 * @returns {string} - O custo avaliado em formato de moeda.
 */
const evaluate = (minutes, stateVal) => {
    let value = minutes * stateVal;
    return 'R$' + value.toFixed(2);
};

/**
 * Converte segundos para minutos.
 * @param {number} seconds - O número de segundos.
 * @returns {string} - Os minutos convertidos.
 */
const parseToMinutes = (seconds) => {
    const minutes = seconds / 60;
    return minutes.toFixed(1);
};

/**
 * Obtém o tempo total de chamadas para um tipo específico.
 * @param {Array} data - Os dados contendo registros de chamadas.
 * @param {string} type - O tipo de chamada.
 * @returns {string} - O tempo total de chamadas em minutos.
 */
const getCallTime = (data, type) => {
    let total = [...data].map((call) => {
        if (call[6] === type) {
            return call[5];
        }
        return null;
    }).filter(t => t);

    total = total.reduce((acc, cur) => {
        const time = cur.split(/:/);
        const hours = +parseFloat(time[0]);
        const minutes = +parseFloat(time[1]);
        const seconds = +parseFloat(time[2]);

        const totalTime = (hours * 60 * 60) + (minutes * 60) + seconds;
        return acc + totalTime;
    }, 0);

    return parseToMinutes(total);
};

/**
 * Calcula o valor total da fatura para uma secretaria.
 * @param {Object} sec - O objeto da secretaria contendo valores de chamadas.
 * @returns {string} - O valor total da fatura em formato de moeda.
 */
const faturate = (sec) => {
    let sum = 0;
    let vc1 = sec["Valor VC1"].split('$')[1];
    let vc2 = sec["Valor VC2"].split('$')[1];
    let vc3 = sec["Valor VC3"].split('$')[1];
    let lc = sec["Valor LC"].split('$')[1];
    let ldn = sec["Valor LDN"].split('$')[1];
    sum = parseFloat(vc1) + parseFloat(vc2) + parseFloat(vc3) + parseFloat(lc) + parseFloat(ldn);
    return 'R$' + sum.toFixed(2);
};


export const generatePDF = async (data, prefName, remove) => {
    let rows = [];
    let headersTable = ['Item', "Tempo", "Valor"];
    if (remove) {
        headersTable = ['Item', "Tempo"];
    }

    let linhas = Array.isArray(data.linhas) ? data.linhas : [data.linhas];
    const image = await imageUrlToBase64(
        "https://erp.voxcity.com.br/VOXLOGOHorizintal.png"
    );
    let lines = [
        {
            'Item': 'VC1',
            'Tempo': data["Tempo VC1"],
            'Valor': data["Valor VC1"]
        },
        {
            'Item': 'VC2',
            'Tempo': data["Tempo VC2"],
            'Valor': data["Valor VC2"]
        },
        {
            'Item': 'VC3',
            'Tempo': data["Tempo VC3"],
            'Valor': data["Valor VC3"]
        },
        {
            'Item': 'LC',
            'Tempo': data["Tempo LC"],
            'Valor': data["Valor LC"]
        },
        {
            'Item': 'LDN',
            'Tempo': data["Tempo LDN"],
            'Valor': data["Valor LDN"]
        }
    ];

    lines.forEach((line, index) => {
        let row = [];
        headersTable.forEach(header => {
            row.push(line[header]);
        });
        rows.push(row);
    });


    const docDefinition = {
        content: [
            {
                text: 'Cliente: ',
                alignment: "left",
                fontSize: 16,
                bold: true,
                margin: [0, 50, 0, 0]
            },
            {
                text: `${prefName}`,
                alignment: "left",
                fontSize: 14,
                margin: [70, -17, 0, 0]
            },
            {
                text: 'Secretaria: ',
                alignment: "left",
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 0]
            },
            {
                text: `${data.secretaria}`,
                alignment: "left",
                fontSize: 14,
                margin: [90, -17, 0, 0]
            },
            {
                text: 'Referência: ',
                alignment: "left",
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 0]
            },
            {
                text: "N/A",
                alignment: "left",
                fontSize: 14,
                margin: [90, -17, 0, 0]
            },
            {
                stack: [
                    {
                        image,
                        width: 200,
                        alignment: "right",
                        margin: [0, -120, 0, 0]
                    },
                    {
                        text: 'Linhas: ',
                        style: "subheader",
                        alignment: "left",
                        fontSize: 14,
                        margin: [0, 10, 0, 10]
                    },
                    {
                        text: `${linhas.join(',  ')}`,
                        style: "subheader",
                        alignment: "left",
                        fontSize: 12,
                        margin: [0, 0, 0, 6]
                    }
                ],
                alignment: "left",
                margin: [0, 0, 0, 15]
            },
            {
                table: {
                    widths: ["*", "*", "*"],
                    headerRows: 1,
                    margin: [0, 20, 0, 20],
                    body: [
                        headersTable,
                        ...rows
                    ]
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
                    }
                }
            },
            {
                text: remove ? "" : `Total: ${data.Fatura}`,
                alignment: "right",
                fontSize: 14,
                bold: true,
                margin: [0, 20, 0, 0]
            }
        ],
        footer: function (currentPage, pageCount) {
            return {
                text: 'Relatório emitido por Voxcity Tecnologia em ' + new Date().toLocaleDateString(),
                alignment: 'center',
                fontSize: 10,
                margin: [0, 20, 0, 0]
            };
        }
    };

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    return new Promise((resolve, reject) => {
        let fileName = `${prefName}_${data.secretaria}.pdf`;
        pdfMake.createPdf(docDefinition).download(fileName, () => {
            // Quando o PDF terminar de ser baixado, resolva a promessa
            resolve(true); // Pode retornar algo mais específico se necessário
        });
    });
};


export const generatePDFExcel = async (data, prefName, remove) => {
    let rows = [];
    let rows2 = [];
    let headersTable = ['Item', "Tempo", "Valor"];
    let headersTable2 = ['Data', "Origem", "Destino", "Tempo Faturado", "Valor"];
    if (remove) {
        headersTable = ['Item', "Tempo"];
        headersTable2 = ['Data', "Origem", "Destino", "Tempo Faturado"];
    }


    let linhas = Array.isArray(data.linhas) ? data.linhas : [data.linhas];
    const image = await imageUrlToBase64(
        "https://erp.voxcity.com.br/VOXLOGOHorizintal.png"
    );
    let lines = [
        {
            'Item': 'VC1',
            'Tempo': data["Tempo VC1"],
            'Valor': data["Valor VC1"]
        },
        {
            'Item': 'VC2',
            'Tempo': data["Tempo VC2"],
            'Valor': data["Valor VC2"]
        },
        {
            'Item': 'VC3',
            'Tempo': data["Tempo VC3"],
            'Valor': data["Valor VC3"]
        },
        {
            'Item': 'LC',
            'Tempo': data["Tempo LC"],
            'Valor': data["Valor LC"]
        },
        {
            'Item': 'LDN',
            'Tempo': data["Tempo LDN"],
            'Valor': data["Valor LDN"]
        }
    ];

    let lines2 = data?.Detalhes?.[0]?.data.map((detalhe) => {
        return [
            detalhe?.[1] ?? 'N/A',   // Data
            detalhe?.[2] ?? 'N/A',   // Destino
            detalhe?.[3] ?? 'N/A',   // Origem
            detalhe?.[5] ?? 'N/A',   // Tempo Faturado
            detalhe?.[8] ?? 'N/A'    // Valor
        ];
    }) || [];

    let newLines2 = [];
    lines2.forEach(subArray => {
        let obj = {
            Data: subArray[0],
            Destino: subArray[1],
            Origem: subArray[2],
            'Tempo Faturado': subArray[3],
            Valor: subArray[4]
        };
        newLines2.push(obj);
    });

    newLines2.forEach((line, index) => {
        let row = [];
        headersTable2.forEach(header => {
            row.push(line[header]);
        });
        rows2.push(row);
    });

    lines.forEach((line, index) => {
        let row = [];
        headersTable.forEach(header => {
            row.push(line[header]);
        });
        rows.push(row);
    });

    const docDefinition = {
        content: [
            {
                text: 'Cliente: ',
                alignment: "left",
                fontSize: 16,
                bold: true,
                margin: [0, 50, 0, 0]
            },
            {
                text: `${prefName}`,
                alignment: "left",
                fontSize: 14,
                margin: [70, -17, 0, 0]
            },
            {
                text: 'Secretaria: ',
                alignment: "left",
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 0]
            },
            {
                text: `${data.secretaria}`,
                alignment: "left",
                fontSize: 14,
                margin: [90, -17, 0, 0]
            },
            {
                text: 'Referência: ',
                alignment: "left",
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 0]
            },
            {
                text: 'N/A',
                alignment: "left",
                fontSize: 14,
                margin: [90, -17, 0, 0]
            },
            {
                stack: [
                    {
                        image,
                        width: 200,
                        alignment: "right",
                        margin: [0, -120, 0, 0]
                    },
                    {
                        text: 'Linhas: ',
                        style: "subheader",
                        alignment: "left",
                        fontSize: 14,
                        margin: [0, 10, 0, 10]
                    },
                    {
                        text: `${linhas.join(',  ')}`,
                        style: "subheader",
                        alignment: "left",
                        fontSize: 12,
                        margin: [0, 0, 0, 6]
                    }
                ],
                alignment: "left",
                margin: [0, 0, 0, 15]
            },
            {
                table: {
                    widths: ["*", "*", "*"],
                    headerRows: 1,
                    margin: [0, 20, 0, 20],
                    body: [
                        headersTable,
                        ...rows
                    ]
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
                    }
                }
            },
            {
                text: remove ? "" : `Total: ${data.Fatura}` ,
                alignment: "right",
                fontSize: 14,
                bold: true,
                margin: [0, 20, 0, 0]
            },
            {
                text: 'Detalhes',
                alignment: "center",
                fontSize: 16,
                bold: true,
                margin: [0, 20, 0, 20]
            },
            {
                table: {
                    widths: ["*", "*", "*", "*", "*"],
                    headerRows: 1,
                    margin: [0, 20, 0, 20],
                    body: [
                        headersTable2,
                        ...rows2
                    ]
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
                    }
                }
            }
        ],
        footer: function (currentPage, pageCount) {
            return {
                text: 'Relatório emitido por Voxcity Tecnologia em ' + new Date().toLocaleDateString(),
                alignment: 'center',
                fontSize: 10,
                margin: [0, 20, 0, 0]
            };
        }
    };

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    return new Promise((resolve, reject) => {
        let fileName = `${prefName}_${data.secretaria}.pdf`;
        pdfMake.createPdf(docDefinition).download(fileName, () => {
            resolve(true);
        });
    });
};


async function imageUrlToBase64(url) {
    // função para converter a imagem em base64, mas imagem esta sem extensão;
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error("Failed to fetch image");
    }
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
    });
}